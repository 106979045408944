import AssetsGroupSystemsFinderInterfaceClass from "../infrastructure/assets-group-systems-summary-finder-interface.js";
import AssetsGroupSystemFinderValidator from "../domain/assets-group-system-summary-finder-validator.js";

import AssetsDefaultGroupSystemsSummaryStoreUtil from "./utils/assets-default-group-systems-summary-store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.validator = AssetsGroupSystemFinderValidator;
		this.backend_interface = new AssetsGroupSystemsFinderInterfaceClass();
		this.defaul_group_store_util = AssetsDefaultGroupSystemsSummaryStoreUtil;
	}

	async get_default_assets_group_systems_summary(force_download = false) {
		try {
			var cached_asset = await this.defaul_group_store_util.get_items();
			if (cached_asset && !force_download) return cached_asset;

			const interface_result =
				await this.backend_interface.get_default_assets_group_systems_summary();

			const validated_data = interface_result.map((item) =>
				this.validator.validate(item)
			);
			await this.defaul_group_store_util.save_items(
				validated_data,
				moment().add(1, "hours").toDate()
			);

			return await this.defaul_group_store_util.get_items();
		} catch (e) {
			throw new AppError(
				"error_get_default_assets_group_systems_summary",
				"general.errors.general_error",
				e
			);
		}
	}
}
